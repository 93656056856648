import React, { useState, useEffect } from 'react';
import { Modal, Dropdown, Button, Checkbox, Label } from 'semantic-ui-react';
import { ScheduleCourseDto, ModulePart, WeekDay } from '../../models/interlift/interlift_schedule';
import { toast } from 'react-toastify';

type ScheduleModalProps = {
  open: boolean;
  slot: ScheduleCourseDto | null;
  isEditing: boolean;
  onSave: (updatedSlot: ScheduleCourseDto) => void;
  onClose: () => void;
  existingSlots: ScheduleCourseDto[];
  courseModules: ScheduleCourseDto[];
  selectedDay: WeekDay | null;
  selectedSlot: string | null;
};

const ScheduleModal: React.FC<ScheduleModalProps> = ({
  open,
  slot,
  isEditing,
  onSave,
  onClose,
  existingSlots,
  courseModules,
  selectedDay,
  selectedSlot,
}) => {
  const [selectedCourse, setSelectedCourse] = useState<string>(slot?.courseName || '');
  const [selectedModule, setSelectedModule] = useState<string>(slot?.moduleCode || '');
  const [selectedModuleName, setSelectedModuleName] = useState<string>(slot?.moduleName || '');
  const [selectedModulePart, setSelectedModulePart] = useState<ModulePart | undefined>(slot?.modulePart);
  const [includeQuiz, setIncludeQuiz] = useState<boolean>(slot?.includeQuiz || false);
  const [includeNRMaterial, setIncludeNRMaterial] = useState<boolean>(slot?.includeNRMaterial || true);
  const [includeGRMaterial, setIncludeGRMaterial] = useState<boolean>(slot?.includeGRMaterial || true);
  const [scheduledCode, setScheduledCode] = useState<string>(slot?.scheduledCode || '');

  // For slot (Education Hour) selection
  const [selectedHour, setSelectedHour] = useState<string>(selectedSlot || '');

  // Available slots (Education Hour 1 to 6)
  const availableSlots = Array.from({ length: 6 }, (_, i) => `Education Hour ${i + 1}`);

  // Filter out slots that are already taken (but include the current slot if editing)
  const availableSlotOptions = availableSlots.filter(slotName => {
    return !existingSlots.some(
      existingSlot =>
        existingSlot.slot === slotName &&
        existingSlot.day === selectedDay &&
        existingSlot.slot !== selectedHour
    );
  });

  // Ensure that the current slot is part of the options if editing
  const slotOptions = isEditing
    ? [...availableSlotOptions, selectedHour]  // Include the current slot in edit mode
    : availableSlotOptions;  // Only show available slots in new mode

  useEffect(() => {
    if (isEditing && slot) {
      setSelectedCourse(slot.courseName);
      setSelectedModule(slot.moduleCode);
      setSelectedModuleName(slot.moduleName);
      setSelectedModulePart(slot.modulePart);
      setIncludeQuiz(slot.includeQuiz);
      setIncludeNRMaterial(slot.includeNRMaterial);
      setIncludeGRMaterial(slot.includeGRMaterial);
      setSelectedHour(slot.slot);  // Ensure the current slot is selected in edit mode
    }
  }, [isEditing, slot]);

  const isPrepToParentCourse = (courseName: string): boolean => {
    return courseName.toLowerCase() === 'prep to parent'.toLowerCase();
  };

  useEffect(() => {
    if (isPrepToParentCourse(selectedCourse)) {
      setSelectedModulePart(ModulePart.FR1);
      setIncludeQuiz(false);
      setIncludeNRMaterial(false);
    }
  }, [selectedCourse]);


  const handleModuleChange = (moduleCode: string) => {
    const selectedModule = courseModules.find(module => module.moduleCode === moduleCode);
    if (selectedModule) {
      setSelectedModule(moduleCode);
      setSelectedModuleName(selectedModule.moduleName);
      generateScheduledCode(selectedModulePart, moduleCode);
    }
  };

  const generateScheduledCode = (modulePart: ModulePart | undefined, moduleCode: string) => {
    if (modulePart) {
      const numberPart = getNumberPart(modulePart);
      const letterPart = getLetterPart(modulePart);
      const generatedScheduledCode = `${numberPart}${moduleCode}${letterPart}`;
      setScheduledCode(generatedScheduledCode);
    }
  };

  const getNumberPart = (modulePart: ModulePart): string => {
    switch (modulePart) {
      case ModulePart.FR1:
        return '1';
      case ModulePart.FR2:
        return '2';
      case ModulePart.FR3:
        return '3';
      case ModulePart.FR4:
        return '4';
      case ModulePart.SR1A:
      case ModulePart.SR1B:
        return '1';
      case ModulePart.SR2A:
      case ModulePart.SR2B:
        return '2';
      default:
        return '';
    }
  };

  const getLetterPart = (modulePart: ModulePart): string => {
    switch (modulePart) {
      case ModulePart.FR1:
      case ModulePart.FR2:
      case ModulePart.FR3:
      case ModulePart.FR4:
        return 'F';
      case ModulePart.SR1A:
      case ModulePart.SR2A:
        return 'A';
      case ModulePart.SR1B:
      case ModulePart.SR2B:
        return 'B';
      default:
        return '';
    }
  };

  const modulePartOptions = Object.entries(ModulePart)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({
      key: value,
      value,
      text: key,
    }));

  // Sort courses alphabetically
  const uniqueCourses = Array.from(new Set(courseModules.map(course => course.courseName)))
    .sort()  // Sort alphabetically
    .map(courseName => ({
      key: courseName,
      value: courseName,
      text: courseName,
    }));


  // Sort modules by moduleCode and moduleName combination
  const availableModules = courseModules
    .filter(course => course.courseName === selectedCourse)
    .filter(course => {
      const isModuleInUse = existingSlots.some(existingSlot =>
        existingSlot.moduleName === course.moduleName
      );
      return !isModuleInUse;
    }
    )
    .sort((a, b) => {
      const aText = `${a.moduleCode} - ${a.moduleName}`;
      const bText = `${b.moduleCode} - ${b.moduleName}`;
      return aText.localeCompare(bText);  // Sort by moduleCode + moduleName combination
    })
    .map(course => ({
      key: course.moduleCode,
      value: course.moduleCode,
      text: `${course.moduleCode} - ${course.moduleName}`,
    }));

  const handleSave = () => {
    if (!selectedDay || !selectedHour || !selectedCourse || !selectedModule || !selectedModulePart) {
      toast.error('All fields must be selected before saving.');
      return;
    }

    // Check for duplicates
    const duplicateSlot = existingSlots.some(
      (existingSlot) =>
        //existingSlot.day === selectedDay &&
        //existingSlot.slot !== selectedHour &&
        existingSlot.moduleName === selectedModuleName
    );

    if (duplicateSlot) {
      toast.error('Duplicate module for this schedule. Please select a different module.');
      return;
    }

    const updatedSlot: ScheduleCourseDto = {
      day: selectedDay,
      slot: selectedHour,
      courseName: selectedCourse,
      moduleCode: selectedModule,
      moduleName: selectedModuleName,
      modulePart: selectedModulePart,
      includeQuiz,
      includeNRMaterial,
      includeGRMaterial,
      scheduledCode: scheduledCode,
    };

    onSave(updatedSlot);
  };

  const filteredModulePartOptions = isPrepToParentCourse(selectedCourse)
  ? modulePartOptions.filter(option => option.value === ModulePart.FR1)
  : modulePartOptions;

  return (
    <Modal open={open} onClose={onClose} closeIcon closeOnEscape closeOnDocumentClick>
      <Modal.Header>{isEditing ? 'Edit Slot' : 'Add Slot'}</Modal.Header>
      <Modal.Content>
        <Label size="large">Day: {selectedDay !== null ? WeekDay[selectedDay] : ''}</Label>
        <br />
        {/* Dropdown for Education Hour */}
        <Dropdown
          placeholder="Select Education Hour"
          fluid
          selection
          search
          size="huge"
          value={selectedHour}
          onChange={(e, { value }) => setSelectedHour(value as string)}
          options={slotOptions.map(slot => ({
            key: slot,
            value: slot,
            text: slot,
          }))}  // Display Education Hour 1 to 6, including the current one in edit mode
        />
        <br />

        <Dropdown
          placeholder="Select Course"
          fluid
          selection
          search
          size="huge"
          value={selectedCourse}
          onChange={(e, { value }) => setSelectedCourse(value as string)}
          options={uniqueCourses}
        />
        <br />

        <Dropdown
          placeholder="Select Module"
          fluid
          selection
          search
          size="huge"
          value={selectedModule}
          onChange={(e, { value }) => handleModuleChange(value as string)}
          options={availableModules}
        />
        <br />

        <Dropdown
          placeholder="Select Module Part"
          fluid
          selection
          search
          size="huge"
          value={selectedModulePart}
          onChange={(e, { value }) => {
            setSelectedModulePart(value as ModulePart);
            generateScheduledCode(value as ModulePart, selectedModule);
          }}
          options={filteredModulePartOptions}
        />
        <br />

        <Checkbox
          label="Include Quiz"
          checked={includeQuiz}
          onChange={(e, { checked }) => setIncludeQuiz(!!checked)}
          disabled={isPrepToParentCourse(selectedCourse)}
        />
        <br />

        <Checkbox
          label="Include NR (Notes & Reflections) Material"
          checked={includeNRMaterial}
          onChange={(e, { checked }) => setIncludeNRMaterial(!!checked)}
          disabled={isPrepToParentCourse(selectedCourse)}
        />
        <br/>

        <Checkbox
          label="Include GR (gender-response) Material"
          checked={includeGRMaterial}
          onChange={(e, { checked }) => setIncludeGRMaterial(!!checked)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button primary onClick={handleSave}>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ScheduleModal;
